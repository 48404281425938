import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCategoryTypes } from "../../Utils/JobHelpers";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ProductsTableRow from "./ProductsTableRow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const ProductsTable = (props) => {
  const {
    handleUpdateProductStore,
    products,
    productCategories,
    handleUpdateProductChangeList,
    handleArchiveProduct,
    setExpandedCategory
  } = props;
  const [localProducts, setLocalProducts] = useState(products);

  // useEffect(() => {
  //   setLocalProducts(products);
  // }, [products]);

  const handleUpdateLocalProduct = (field, value, productId) => {
    let _product = localProducts.find((p) => p.id === productId);
    if (_product) {
      if (field === "productCategoryId") {
        _product = { ..._product, [field]: value.id, type: value.name };
        setLocalProducts(
          localProducts.map((p) => (p.id === productId ? _product : p))
        );
      } else {
        _product = { ..._product, [field]: value };
        setLocalProducts(
          localProducts.map((p) => (p.id === productId ? _product : p))
        );
      }
      handleUpdateProductChangeList([_product]);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const fromIndex = source.index;
    const toIndex = destination.index;

    // same category
    if (fromIndex !== toIndex) {
      reorderProducts(fromIndex, toIndex);
    }
  };

  const reorderProducts = (fromIndex, toIndex) => {
    // Step 1: Adjust the list to move the item from `fromIndex` to `toIndex`
    const updatedLocalProducts = [...localProducts].sort(
      (a, b) => a.displayOrder - b.displayOrder
    );
    const [movedItem] = updatedLocalProducts.splice(fromIndex, 1);
    updatedLocalProducts.splice(toIndex, 0, movedItem);

    const reorderedProducts = updatedLocalProducts.map((_product, index) => ({
      ..._product,
      displayOrder: index + 1,
    }));

    setLocalProducts(reorderedProducts);
    handleUpdateProductChangeList(reorderedProducts);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div className="product-table-root" ref={provided.innerRef}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 30 }}></TableCell>
                  <TableCell style={{ minWidth: 190 }}>Type</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell style={{ width: 190 }}>Rate Type</TableCell>
                  <TableCell style={{ width: 150 }}>Calculation Type</TableCell>
                  <TableCell style={{ width: 160 }}>Cost Price</TableCell>
                  <TableCell style={{ width: 130 }}>Sell Price</TableCell>
                  <TableCell style={{ width: 160 }}>Min Sell Price</TableCell>
                  <TableCell style={{ width: 125 }}>Pass Through</TableCell>
                  <TableCell style={{ width: 170 }}>Task Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {localProducts
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  .map((product, index) => (
                    <ProductsTableRow
                      key={index}
                      index={index}
                      product={product}
                      productCategories={productCategories}
                      handleUpdateLocalProduct={handleUpdateLocalProduct}
                      handleArchiveProduct={handleArchiveProduct}
                      setExpandedCategory={setExpandedCategory}
                    />
                  ))}
              </TableBody>
            </Table>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, {})(ProductsTable);
