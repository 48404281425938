import Close from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContentState,
  compositeDecorator,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import React, { useState, useEffect, Fragment } from "react";
import PaymentCards from "./PaymentCards";
import ConfirmJobDialog from "./ConfirmJobDialog";
import { connect } from "react-redux";
import { setTeamMembersStore, updateJobStore } from "../../reduxActions/job";
import axios from "axios";
import DialogWithHtmlContent from "../Shared/DialogWithHtmlContent";
import AdvancedInvoiceDialog from "./AdvancedInvoiceDialog";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../context/user/UserContext";
import _ from "lodash";
import AddTeamMemebersDialog from "./AddTeamMembersDialog";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ProcessingSpinnerDialogV2 from "../Shared/Spinner/ProcessingSpinnerDialogV2";
import InternalRevenueDialog from "./InternalBilling/InternalRevenueDialog";
import {
  calculateJoblinesTotal,
  getInternalCompanyId,
  hasProductionCategories,
} from "../../Utils/JobHelpers";
import { currencySymbolFormat } from "../../Utils/GeneralUtils";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { useAuth } from "../../context/auth/AuthContext";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CheckJobDialog = (props) => {
  const { setTeamMembersStore, updateJobStore } = props;
  const { allUsers } = useUserContext();
  const { user } = useAuth();
  const history = useHistory();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [openConfirmJob, setOpenConfirmJob] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [convertedEmailHtml, setConvertedEmailHtml] = useState(null);
  const [openPreviewEmail, setOpenPreviewEmail] = useState(false);
  const [fullEmailHtml, setFullEmailHtml] = useState(null);
  const [openAdvancedInvoice, setOpenAdvancedInvoice] = useState(false);
  const [checkSum, setCheckSum] = useState(0);
  const [sendingJob, setSendingJob] = useState(false);
  const [openAddTeamMembersDialog, setOpenAddTeamMembersDialog] =
    useState(false);
  const [isFirstJobForContact, setIsFirstJobForContact] = useState(false);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);

  const [jobOrganisation, setJobOrganisation] = useState(
    props.selectedOrganisation?.id
  );
  const [jobOrgIsDifferent, setJobOrgIsDifferent] = useState(false);
  const [internalRevenue, setInternalRevenue] = useState([]);
  const [internalBillingRevenueUsage, setInternalBillingRevenueUsage] = useState([]);
  const [openInternalRevenueDialog, setOpenInternalRevenueDialog] =
    useState(false);
  const [isSavingInternalRevenue, setIsSavingInternalRevenue] = useState(false);
  const [isSavingInternalRevenueUsage, setIsSavingInternalRevenueUsage] = useState(false);
  const [isApprovingJob, setIsApprovingJob] = useState(false);
  const [
    showManualApproveConfirmationDialog,
    setShowManualApproveConfirmationDialog,
  ] = useState(false);

  useEffect(() => {
    if (
      props.job &&
      props.job.organisationId !== props.selectedOrganisation?.id &&
      !jobOrgIsDifferent
    ) {
      setJobOrgIsDifferent(true);
      setJobOrganisation(props.job.organisationId);
    }
  }, [props.job]);

  const onEditorStateChange = (editorState) => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setConvertedEmailHtml(html);
    setEditorState(editorState);
  };

  useEffect(() => {
    if (props.emailHtml) {
      loadCustomEmailHtml();
    }
  }, [props.emailHtml]);

  useEffect(() => {
    setCheckSum((checksum) => checksum + 1);
  }, [props.job.paymentType]);

  useEffect(() => {
    checkIfFirstJob();
    getOpenAndAllocatedInternalRevenue();
  }, []);

  useEffect(() => {
    console.log("props.job");
    console.log(props.job);
  }, [props.job]);

  const checkIfFirstJob = () => {
    axios
      .get(`Job/IsFirstJob/${props.job.id}`)
      .then((res) => {
        if (res.data) {
          if (props.job.jobTeamMembers.length === 0) {
            setOpenAddTeamMembersDialog(true);
          }
          setIsFirstJobForContact(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadCustomEmailHtml = () => {
    loadEditorHtml(props.emailHtml.customBodyText);
  };

  const loadEditorHtml = (html) => {
    const blocksFromHTML = convertFromHTML(html);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    setEditorState(EditorState.createWithContent(state, compositeDecorator));
  };

  const resetEmail = () => {
    axios.post(`Job/GetSignOffEmail`, props.job).then(({ data }) => {
      const initalHtmlTemplate = data.customBodyText;
      setConvertedEmailHtml(initalHtmlTemplate);
      loadEditorHtml(initalHtmlTemplate);
      autoSaveEditorChanges(initalHtmlTemplate);
    });
  };

  const previewFullEmail = () => {
    axios.post(`Job/PreviewFullSignOffEmail`, props.job).then(({ data }) => {
      setFullEmailHtml(data.fullEmail);
      setOpenPreviewEmail(true);
    });
  };

  const handleClosePreviewEmail = () => {
    setOpenPreviewEmail(false);
    setFullEmailHtml(null);
  };

  const handleCallContactButton = () => {
    setOpenConfirmJob(true);
  };

  const handleCloseConfirmJobDialog = () => {
    setOpenConfirmJob(false);
  };

  const handleCloseAdvancedInvoiceDialog = () => {
    setOpenAdvancedInvoice(false);
  };

  const handleOnChangeTeamMembers = (e, selectedItems, actionType, details) => {
    if (actionType === "selectOption") {
      axios
        .post(`Job/AddTeamMemberToJob/${props.job.id}/${details.option.id}`)
        .then(({ data }) => {
          let team = [...props.job.jobTeamMembers];
          team.push(data);
          setTeamMembersStore(team);
        });
    } else {
      let userId = 0;
      if (details) {
        userId = details.option.id;
      }
      axios
        .post(`Job/RemoveTeamMemberFromJob/${props.job.id}/${userId}`)
        .then(({ data }) => {
          let team = [...props.job.jobTeamMembers];
          setTeamMembersStore(
            userId === 0 ? [] : team.filter((t) => t.userId !== userId)
          );
        });
    }

    setTeamMembers(selectedItems);
  };

  const handleUpdateTeamMembers = (teamMembers) => {
    setLoadingTeamMembers(true);
    axios
      .post(`Job/AddTeamMembersToJob/${props.job.id}`, teamMembers)
      .then((res) => {
        let team = res.data;
        setTeamMembersStore(team);
      })
      .finally(() => {
        setOpenAddTeamMembersDialog(false);
        setLoadingTeamMembers(false);
      });
  };

  const autoSaveEditorChanges = (initalHtmlTemplate = null) => {
    if (convertedEmailHtml || initalHtmlTemplate) {
      let jobEmail = { ...props.emailHtml };
      jobEmail.customBodyText = initalHtmlTemplate
        ? initalHtmlTemplate
        : convertedEmailHtml;
      axios.put(`Job/UpdateJobEmail`, jobEmail);
    }
  };

  const sendJob = () => {
    setSendingJob(true);
    let _job = _.cloneDeep(props.job);
    if (user?.email) {
      _job.user = user?.email;
    }
    axios
      .put("Job/SendJob", _job)
      .then(() => {
        setSendingJob(false);
        history.push("/home");
      })
      .catch((err) => {
        alert("Failed to send job. If problem persists please contact IT");
        setSendingJob(false);
      });
  };

  const getOpenAndAllocatedInternalRevenue = () => {
    if (
      getInternalCompanyId() === props.job.companyId &&
      hasProductionCategories(props.job?.jobCategories)
    ) {
      axios
        .get(`Job/GetOpenInternalRevenueAndAllocatedToJob/${props.job?.id}`, {
          params: {
            category: "Magnesium Production",
            status: "OPEN",
          },
        })
        .then(({ data }) => {
          setInternalRevenue(data.openInternalBillingRevenue);
          setInternalBillingRevenueUsage(data.internalBillingRevenueUsage)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCloseInternalRevenueDialog = () => {
    setOpenInternalRevenueDialog(false);
  };

  const handleUseInternalRevenue = (selectedInternalRevenue) => {
    setIsSavingInternalRevenue(true);
    axios
      .post(
        `Job/AllocateInternalRevenue/${props.job?.id}/${selectedInternalRevenue?.id}`
      )
      .then(({ data }) => {
        updateJobStore("internalBillingRevenueUsage", [
          ...props.job?.internalBillingRevenueUsage,
          data,
        ]);
        getOpenAndAllocatedInternalRevenue();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsSavingInternalRevenue(false));
  };

  const handleRemoveInternalRevenue = (record) => {
    setIsSavingInternalRevenueUsage(true);
    axios
      .delete(
        `Job/RemoveInternalRevenueUsage/${props.job?.id}/${record?.internalBillingRevenueId}/${record?.id}`
      )
      .then(({ data }) => {
        updateJobStore(
          "internalBillingRevenueUsage",
          props.job?.internalBillingRevenueUsage?.filter(
            (i) =>
              i.internalBillingRevenueId !== record?.internalBillingRevenueId && i.jobId !== props.job?.id
          )
        );
        getOpenAndAllocatedInternalRevenue();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsSavingInternalRevenueUsage(false));
  };

  const handleManualApproval = () => {
    setIsApprovingJob(true);
    axios
      .post(`Job/ManuallyApproveJob`, {
        jobId: props.job.id,
        reason: "Internal Quote Approval",
        user: user?.email,
      })
      .then(() => {
        props.setopenSnack(true);
        props.setsnackSeverity("success");
        props.setsnackText(`Job has been manually approved`);
        setShowManualApproveConfirmationDialog(false)
        props.setIsReadOnly(true)
      })
      .catch((e) => {
        console.log(e);
        props.setopenSnack(true);
        props.setsnackSeverity("error");
        props.setsnackText(`Unable to approve job`);
      })
      .finally(() => setIsApprovingJob(false));
  };

  return (
    <Fragment>
      {showManualApproveConfirmationDialog && (
        <ConfirmationDialog
          open={showManualApproveConfirmationDialog}
          handleClose={() => setShowManualApproveConfirmationDialog(false)}
          submitLabel={"Confirm"}
          title={"Before we continue..."}
          description={`Are you sure you want to manually approve "${props.job?.jobName}"`}
          handleSubmit={handleManualApproval}
          loading={isApprovingJob}
        />
      )}
      {sendingJob && props.job?.company?.hasSignedMsa !== true && (
        <ProcessingSpinnerDialogV2
          duration={60}
          message={"Generating MSA"}
          loading={sendingJob}
        />
      )}
      {openConfirmJob && (
        <ConfirmJobDialog
          open={openConfirmJob}
          handleClose={handleCloseConfirmJobDialog}
        />
      )}
      {openPreviewEmail && fullEmailHtml && (
        <DialogWithHtmlContent
          isOpen={openPreviewEmail}
          handleClose={handleClosePreviewEmail}
          htmlContent={fullEmailHtml}
          title="Full Email Preview"
        />
      )}
      {openAdvancedInvoice && !props.isReadOnly && (
        <AdvancedInvoiceDialog
          open={openAdvancedInvoice}
          organisationId={jobOrganisation}
          handleClose={handleCloseAdvancedInvoiceDialog}
          paymentType={props.job.paymentType ?? "END_OF_MONTH_BILLING"}
          job={props.job}
        />
      )}
      {openInternalRevenueDialog && !props.isReadOnly && (
        <InternalRevenueDialog
          open={openInternalRevenueDialog}
          internalRevenue={internalRevenue}
          internalBillingRevenueUsage={internalBillingRevenueUsage}
          handleClose={handleCloseInternalRevenueDialog}
          handleUseInternalRevenue={handleUseInternalRevenue}
          handleRemoveInternalRevenue={handleRemoveInternalRevenue}
          isSavingInternalRevenue={isSavingInternalRevenue}
          isSavingInternalRevenueUsage={isSavingInternalRevenueUsage}
          totalAllocated={calculateJoblinesTotal(internalBillingRevenueUsage, "amount")}
          subtotal={calculateJoblinesTotal(props.job?.jobLines, "total")}
        />
      )}
      {/* {openAddTeamMembersDialog && (
        <AddTeamMemebersDialog
          open={openAddTeamMembersDialog}
          handleClose={() => {
            setOpenAddTeamMembersDialog(false);
          }}
          jobId={props.job.id}
          handleSave={handleUpdateTeamMembers}
        />
      )} */}
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        classes={{ paper: "quote-preview-dialog" }}
      >
        <DialogTitle id="quote-preview-dialog-title" className="check-job-dialog-title-container">
          Quote Preview
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} style={{ height: "100%" }}>
            <Grid item xs={8}>
              <iframe
                key={checkSum}
                src={`https://approvals.thompsonspencer.com/${props.job.id}/${props.job.token}/1`}
                title="Magnesium Service - Contract"
                width="100%"
                height="100%"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                className="iframe-scale-height"
              ></iframe>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div
                    id="job-email-editor"
                    className="newjob-textfield-jobdescription"
                  >
                    <div
                      className="after-divider"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {!props.isReadOnly && (
                          <Button size="small" onClick={resetEmail}>
                            Reset Email
                          </Button>
                        )}
                      </div>
                      <div>
                        <Button size="small" onClick={previewFullEmail}>
                          Preview Full Email
                        </Button>
                      </div>
                    </div>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="jobdescription-text-editor-wrapper"
                      editorClassName="jobdescription-text-editor"
                      onEditorStateChange={onEditorStateChange}
                      handlePastedText={() => false}
                      readOnly={props.isReadOnly}
                      onBlur={(e) => {
                        autoSaveEditorChanges();
                      }}
                      toolbar={{
                        options: ["inline", "fontSize", "list"],
                        inline: {
                          options: ["bold", "italic", "underline"],
                        },
                        list: {
                          options: ["unordered", "ordered"],
                        },
                      }}
                    />
                  </div>
                </Grid>
                <Divider />
                <Grid item xs={12} id="team-members">
                  {/* {isFirstJobForContact && (
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      multiple
                      disabled={props.isReadOnly || loadingTeamMembers}
                      options={[
                        { id: -1, firstName: "People of Influence", lastName: "" },
                        { id: -2, firstName: "Magnesium", lastName: "" },
                        { id: -3, firstName: "Flying Tiger", lastName: "" },
                        { id: -4, firstName: "Community Management", lastName: "" },
                        ...allUsers
                      ]}
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName
                      }
                      value={teamMembers}
                      onChange={(e, items, type, details) => {
                        if (details && details.option.id < 0) {
                          var newItems = items.filter(i => i.id >= 0);
                          var usersToAdd = [];
                          switch (details.option.firstName) {
                            case "People of Influence":
                              usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("People of Influence"));
                              break;
                            case "Magnesium":
                              usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Magnesium"));
                              break;
                            case "Flying Tiger":
                              usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Flying Tiger"));
                              break;
                            case "Community Management":
                              usersToAdd = allUsers.filter(u => u.teams && u.teams.includes("Community Management"));
                              break;
                            default:
                              break;
                          }
                          usersToAdd.forEach(user => {
                            if (newItems.findIndex(i => i.id === user.id) < 0) {
                              newItems.push(user);
                            }
                          })
                          handleUpdateTeamMembers(newItems);
                        } else {
                          handleOnChangeTeamMembers(e, items, type, details);
                        }
                      }}
                      disableCloseOnSelect
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          {option.id >= 0 && (
                            <Checkbox
                              key={`${option.id}-checkbox`}
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                          )}
                          {option.firstName + " " + option.lastName}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Team Members (Optional)"
                          placeholder="Team Members"
                        />
                      )}
                    />
                  )} */}
                </Grid>
                <Grid item xs={12}>
                  <div
                    className="after-divider"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography variant="subtitle1">Payment Info</Typography>
                    </div>
                    {!props.isReadOnly && (
                      <div style={{ textAlign: "right" }}>
                        <Button
                          onClick={() => {
                            setOpenAdvancedInvoice(true);
                          }}
                          size="small"
                        >
                          Advanced Invoice
                        </Button>
                      </div>
                    )}
                  </div>
                  <PaymentCards
                    job={props.job}
                    paymentType={props.job.paymentType}
                    invoices={props.job.invoice}
                    isReadOnly={true}
                  />
                </Grid>
                {props.job?.internalBillingRevenueUsage?.length > 0 && (
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography variant="caption">Internal Budget Used: </Typography>
                      <Typography variant="caption">
                        {currencySymbolFormat(
                          calculateJoblinesTotal(
                            props.job?.internalBillingRevenueUsage,
                            "amount"
                          )
                        )}{" "}
                      </Typography>
                    </div>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {!props.isReadOnly && internalRevenue.length > 0 && (
                      <div>
                        <Button
                          className="job-button"
                          variant="contained"
                          onClick={() => {
                            setOpenInternalRevenueDialog(true);
                          }}
                        >
                          Internal Budget
                        </Button>
                      </div>
                    )}
                    {!props.isReadOnly && (
                      <div>
                        {props.job?.companyId !== 552 ? (
                          <Button
                            className="job-button"
                            onClick={sendJob}
                            variant="contained"
                            disabled={sendingJob || loadingTeamMembers}
                          >
                            Send Now
                            {sendingJob && (
                              <span>
                                <CircularProgress className="icon-button" />
                              </span>
                            )}
                          </Button>
                        ) : (
                          <div>
                            {calculateJoblinesTotal(props.job?.jobLines, "total")
                              > calculateJoblinesTotal(props.job?.internalBillingRevenueUsage, "amount") ?
                              (
                                <Typography variant="caption">
                                  Approve the job by allocating the full quote amount.{" "}
                                </Typography>
                              ) : (calculateJoblinesTotal(props.job?.jobLines, "total")
                                < calculateJoblinesTotal(props.job?.internalBillingRevenueUsage, "amount")) ?
                                <Typography variant="caption">
                                  The allocated amount is over the quote total. 
                                </Typography>
                                : (
                                  <Button
                                    className="job-button"
                                    variant="contained"
                                    disabled={isApprovingJob}
                                    onClick={() => {
                                      setShowManualApproveConfirmationDialog(true);
                                    }}
                                  >
                                    Manually Approve
                                  </Button>
                                )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  setTeamMembersStore,
  updateJobStore,
})(CheckJobDialog);
