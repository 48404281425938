import { createReducer } from "@reduxjs/toolkit";
import _ from "lodash";
import { v1 as uuidv1 } from "uuid";

const initialJobLines = [
  {
    guid: uuidv1(),
    category: "Pre Production",
    name: "",
    description: "",
    rateType: "",
    quantity: 1,
    minSellPrice: 0,
    sellPrice: 0,
    costPrice: 0,
    total: 0,
    margin: 0,
    product: {},
    productId: 0,
    isPassThrough: 0,
    requiresJournaling: 0,
    allowPassThrough: 0,
    order: 1,
  },
  {
    guid: uuidv1(),
    category: "Post Production",
    name: "",
    description: "",
    rateType: "",
    quantity: 1,
    minSellPrice: 0,
    sellPrice: 0,
    costPrice: 0,
    total: 0,
    margin: 0,
    product: {},
    productId: 0,
    isPassThrough: 0,
    requiresJournaling: 0,
    allowPassThrough: 0,
    order: 1,
  },
  {
    guid: uuidv1(),
    category: "Other",
    name: "",
    description: "",
    rateType: "",
    quantity: 1,
    minSellPrice: 0,
    sellPrice: 0,
    costPrice: 0,
    total: 0,
    margin: 0,
    product: {},
    productId: 0,
    isPassThrough: 0,
    requiresJournaling: 0,
    allowPassThrough: 0,
    order: 1,
  },
];

const initialJobCategories = [];

const initialMagnesiumJobCategories = [
  {
    order: 1,
    name: "Pre Production",
    guid: uuidv1(),
    type: "PRE_PRODUCTION",
  },
  {
    order: 98,
    name: "Post Production",
    guid: uuidv1(),
    type: "POST_PRODUCTION",
  },
  {
    order: 99,
    name: "Other",
    guid: uuidv1(),
    type: "OTHER",
  },
];

const defaultFilters = {
  users: [],
  companyId: 0,
  team: [],
  includeApproved: true,
  status: ["draft", "checked", "sent"],
};

const initialState = {
  job: {
    companyId: 0,
    user: "",
    jobName: "",
    sent: null,
    contactId: 0,
    additional: false,
    status: "draft",
    expectedShootDate: null,
    expectedTransmissionDate: null,
    completionDate: null,
    jobDescription: "",
    created: null,
    token: "",
    shootDays: 0,
    jobLines: [],
    paymentType: "END_OF_MONTH_BILLING",
    parentJobId: null,
    jobCategories: initialJobCategories,
    isRetainer: false,
    retainerDuration: 1,
    currency: "NZD",
    exchangeRate: 1
  },
  totalJobs: 0,
  jobs: [],
  jobsFilter: defaultFilters,
  previousFilter: {},
  exemptions: [],
};

const job = createReducer(initialState, {
  LOG_JOB_ERROR: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      error: payload,
    };
  },

  RESET_JOB_STORE: (state, action) => {
    let _job = { ...initialState.job };
    _job.organisationId = action.payload;

    return {
      ...state,
      job: _job,
    };
  },

  UPDATE_JOB_STORE: (state, action) => {
    const { field, value } = action.payload;

    if(field === "jobCategories"){
      return {
        ...state,
        job: { ...state.job, [field]: value, shootDays: value.filter(c => c.type === "Filming").length },
      };
    }

    return {
      ...state,
      job: { ...state.job, [field]: value },
    };

  },

  UPDATE_ALL_JOB_LINES_STORE: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    jobClone.jobLines = payload;
    return {
      ...state,
      job: jobClone,
    };
  },

  UPDATE_ALL_JOB_CATEGORIES_STORE: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    jobClone.jobCategories = payload;
    return {
      ...state,
      job: jobClone,
    };
  },

  UPDATE_CATEGORY_NAME_STORE: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    if (jobClone?.jobCategories?.length > 0) {
      jobClone.jobCategories = jobClone.jobCategories.map((c) =>
        c.guid === payload.guid ? payload : c
      );
    } else {
      jobClone.jobCategories = [...jobClone.jobCategories, payload];
    }
    return {
      ...state,
      job: jobClone,
    };
  },

  UPDATE_SINGLE_JOB_LINE_STORE: (state, action) => {
    const { jobLineGuid, field, value } = action.payload;
    let jobClone = _.cloneDeep(state.job);
    let jobLineIndex = jobClone.jobLines.findIndex(
      (j) => j.guid === jobLineGuid
    );
    switch (field) {
      case "name":
        jobClone.jobLines[jobLineIndex][field] = value.name;
        jobClone.jobLines[jobLineIndex]["product"] = value;
        break;
      default:
        jobClone.jobLines[jobLineIndex][field] = value;
        break;
    }
    
    const quantity = Number(jobClone.jobLines[jobLineIndex]["quantity"]);
    const sellPrice = Number(jobClone.jobLines[jobLineIndex]["sellPrice"]);
    const costPrice = Number(jobClone.jobLines[jobLineIndex]["costPrice"]);
    const total = quantity * sellPrice;

    // Calculate total when rateType is "Open Budget"
    if (jobClone.jobLines[jobLineIndex]["rateType"] === "Open Budget") {
      const percentageRate = costPrice;

      if (percentageRate && total > 0) {
        if (jobClone.jobLines[jobLineIndex]["calculationMethod"] === "Margin") {
          // Margin calculation
          const margin = percentageRate / 100;
          const totalCost = total * (1 - margin)
          jobClone.jobLines[jobLineIndex]["margin"] = total - totalCost;
          jobClone.jobLines[jobLineIndex]["total"] = total;
        } else {
          // Other calculation methods
          const markup = total * (percentageRate / 100);
          jobClone.jobLines[jobLineIndex]["margin"] = markup;
          jobClone.jobLines[jobLineIndex]["total"] = total + markup;
        }
      } else {
        jobClone.jobLines[jobLineIndex]["margin"] = 0;
        jobClone.jobLines[jobLineIndex]["total"] = total;
      }
    } else {
      const cost = quantity * costPrice;

      jobClone.jobLines[jobLineIndex]["margin"] = total - cost;
      jobClone.jobLines[jobLineIndex]["total"] = total;
    }

    
    jobClone.jobLines[jobLineIndex]["exchangedTotal"] = Number(jobClone.jobLines[jobLineIndex]["total"] * jobClone.exchangeRate).toFixed(2);
    return {
      ...state,
      job: jobClone,
    };
  },

  ADD_PRODUCT_TO_JOB_LINE_STORE: (state, action) => {
    const { jobLineGuid, selectedProduct } = action.payload;

    let jobClone = _.cloneDeep(state.job);
    let jobLineIndex = jobClone.jobLines.findIndex(
      (j) => j.guid === jobLineGuid
    );
    let jobLine = jobClone.jobLines[jobLineIndex];
    let exemptionsClone = _.cloneDeep(state.exemptions);
    let foundExcemption = null;
    for(var i = 0; i < exemptionsClone.length; i++){
      if(exemptionsClone[i].productId == selectedProduct.id && jobClone.companyId == exemptionsClone[i].clientId && exemptionsClone[i].status === "APPROVED"){
        foundExcemption = exemptionsClone[i];
      }
    }

    jobLine.productId = selectedProduct.id;
    jobLine.type = selectedProduct.type;
    jobLine.name = selectedProduct.name;
    jobLine.rateType = selectedProduct.rateType;
    jobLine.description = selectedProduct.description;
    jobLine.minSellPrice = selectedProduct.type?.includes("Media Spend") ? 0 : 
      foundExcemption ? foundExcemption.newLimit : selectedProduct.minSellPrice;
    jobLine.sellPrice = selectedProduct.sellPrice;
    jobLine.costPrice = selectedProduct.type?.includes("Media Spend") ? 0 : selectedProduct.costPrice;
    jobLine.allowPassThrough = selectedProduct.allowPassThrough;
    jobLine.isPassThrough = 0;
    jobLine.requiresJournaling = selectedProduct.requiresJournaling;
    jobLine.calculationMethod = selectedProduct.calculationMethod || "Margin";
    jobLine.total = Number(jobLine.quantity) * Number(jobLine.sellPrice);
    jobLine.margin =
      selectedProduct.rateType && selectedProduct.rateType === "Open Budget"
        ? 0
        : jobLine.total - Number(jobLine.quantity) * Number(jobLine.costPrice);
    jobLine.exchangedTotal = Number(jobLine.total * jobClone.exchangeRate);
    
    jobClone.jobLines[jobLineIndex] = jobLine;

    return {
      ...state,
      job: jobClone,
    };
  },

  ADD_JOB_LINE_STORE: (state, action) => {
    let { newJobLines, category } = action.payload;
    let jobClone = _.cloneDeep(state.job);
    // setting order based on last line for the category
    const jobLinesByCategory = jobClone.jobLines
      .filter((j) => j.jobCategoryGuid === category.guid)
      .sort((a, b) => a.order - b.order);
    let lastOrder =
      jobLinesByCategory[jobLinesByCategory.length - 1]?.order + 1;

    newJobLines.forEach((item) => {
      item.order = isNaN(lastOrder) ? 1 : lastOrder;
      lastOrder++;
    });

    jobClone.jobLines = [...jobClone.jobLines].concat(newJobLines);
    return {
      ...state,
      job: jobClone,
    };
  },

  REMOVE_JOB_LINE_STORE: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    jobClone.jobLines = jobClone.jobLines.filter((j) => j.guid !== payload);
    return {
      ...state,
      job: jobClone,
    };
  },

  REORDER_JOB_LINES_SAME_CATEGORY: (state, action) => {
    const { category, sourceIndex, destinationIndex } = action.payload;
    let jobClone = _.cloneDeep(state.job);

    let jobLinesByCategory = Array.from(
      jobClone.jobLines
        .filter((j) => j.category === category)
        .sort((a, b) => a.order - b.order)
    );
    const [removedItem] = jobLinesByCategory.splice(sourceIndex, 1);

    jobLinesByCategory.splice(destinationIndex, 0, removedItem);

    // re-setting order field
    for (let i = 0; i < jobLinesByCategory.length; i++) {
      jobLinesByCategory[i].order = i + 1;
      jobClone.jobLines = jobClone.jobLines.map((j) =>
        j.guid === jobLinesByCategory[i].guid ? jobLinesByCategory[i] : j
      );
    }

    return {
      ...state,
      job: jobClone,
    };
  },

  MOVE_JOB_LINES_BETWEEN_CATEGORIES: (state, action) => {
    const { fromCategory, toCategory, source, destination } = action.payload;
    let jobClone = _.cloneDeep(state.job);

    let jobLinesByCategory = Array.from(
      jobClone.jobLines
        .filter((j) => j.category === fromCategory)
        .sort((a, b) => a.order - b.order)
    );
    const [removedItem] = jobLinesByCategory.splice(source.index, 1);
    // re-setting order field
    for (let i = 0; i < jobLinesByCategory.length; i++) {
      jobLinesByCategory[i].order = i + 1;
      jobClone.jobLines = jobClone.jobLines.map((j) =>
        j.guid === jobLinesByCategory[i].guid ? jobLinesByCategory[i] : j
      );
    }
    jobLinesByCategory = Array.from(
      jobClone.jobLines
        .filter((j) => j.category === toCategory)
        .sort((a, b) => a.order - b.order)
    );

    jobLinesByCategory.splice(destination.index, 0, removedItem);

    for (let i = 0; i < jobLinesByCategory.length; i++) {
      jobLinesByCategory[i].order = i + 1;
      jobLinesByCategory[i].category = destination.droppableId;

      jobClone.jobLines = jobClone.jobLines.map((j) =>
        j.guid === jobLinesByCategory[i].guid ? jobLinesByCategory[i] : j
      );
    }

    return {
      ...state,
      job: jobClone,
    };
  },

  GET_JOBS: (state, action) => {
    const { payload } = action;
    console.log("GET_JOBS");
    return {
      ...state,
      jobs: payload.jobs,
      totalJobs: payload.totalJobs,
      previousFilter: state.jobsFilter,
    };
  },

  GET_JOBS_ADD_TO_EXISTING: (state, action) => {
    const { payload } = action;
    console.log("GET_JOBS_ADD_TO_EXISTING");
    return {
      ...state,
      jobs: [...state.jobs, ...payload.jobs],
      totalJobs: payload.totalJobs,
      previousFilter: state.previousFilter,
    };
  },

  GET_JOB: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      job: payload,
    };
  },
  UPDATE_JOB: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      job: payload,
      jobs: state.jobs.map((j) => (j.id === payload.id ? payload : j)),
    };
  },
  CREATE_DRAFT_JOB: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      job: payload,
      jobs: [payload, ...state.jobs],
    };
  },

  SET_TEAM_MEMBERS_STORE: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    jobClone.jobTeamMembers = payload;
    return {
      ...state,
      job: jobClone,
      jobs: state.jobs.map((j) => (j.id === jobClone.id ? jobClone : j)),
    };
  },
  UPDATE_JOBS_FILTER: (state, action) => {
    const { field, value } = action.payload;
    return {
      ...state,
      jobsFilter: { ...state.jobsFilter, [field]: value },
    };
  },
  DELETE_JOB_CATEGORY: (state, action) => {
    const { payload } = action;
    let jobClone = _.cloneDeep(state.job);
    jobClone.jobLines = jobClone.jobLines.filter(
      (j) => j.jobCategoryGuid !== payload.guid
    );
    jobClone.jobCategories = jobClone.jobCategories.filter(
      (c) => c.guid !== payload.guid
    );
    return {
      ...state,
      job: jobClone,
    };
  },
  GET_EXEMPTIONS: (state, action) => {
    const { payload } = action;
    return {
      ...state,
      exemptions: payload,
    };
  },
});

export default job;
