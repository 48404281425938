import { ExpandCircleDown } from "@mui/icons-material";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const ProductTypeAccountCodeTable = ({ categories, expenseCodes, setCategories }) => {
  
  

  return (
    <div className="product-categories-table-root">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{width: 200}}>Type</TableCell>
            <TableCell style={{minWidth: 300}}>Revenue Code</TableCell>
            <TableCell style={{minWidth: 300}}>Reteiner Revenue Code</TableCell>
            <TableCell style={{minWidth: 300}}>Cost & Expenses Code</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category, index) => (
            <TableRow key={index}>
              <TableCell >
                <Typography variant="body2">{category.name}</Typography>
              </TableCell>
              <TableCell>
                <Autocomplete
                  options={expenseCodes.filter(
                    (e) => e.accountCode && e.organisationFk === -1
                  )}
                  getOptionLabel={(code) =>
                    code.accountCode
                      ? `${code.accountCode} - ${code.accountName}`
                      : ""
                  }
                  value={
                    expenseCodes.find(
                      (ec) => ec.accountCode == category.revenueAccountCode && ec.organisationFk === -1
                    ) ?? ""
                  }
                  onChange={(e, newValue) => {
                    setCategories(
                      categories.map((c) =>
                        c.id === category.id
                          ? {
                              ...category,
                              revenueAccountCode: newValue?.accountCode,
                            }
                          : c
                      )
                    );
                  }}
                  disableClearable
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  options={expenseCodes.filter(
                    (e) => e.accountCode && e.organisationFk === -1
                  )}
                  getOptionLabel={(code) =>
                    code.accountCode
                      ? `${code.accountCode} - ${code.accountName}`
                      : ""
                  }
                  value={
                    expenseCodes.find(
                      (ec) =>
                        ec.accountCode == category.retainerRevenueAccountCode && ec.organisationFk === -1
                    ) ?? ""
                  }
                  onChange={(e, newValue) => {
                    setCategories(
                      categories.map((c) =>
                        c.id === category.id
                          ? {
                              ...category,
                              retainerRevenueAccountCode: newValue?.accountCode,
                            }
                          : c
                      )
                    );
                  }}
                  disableClearable
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </TableCell>
              <TableCell>
                <Autocomplete
                  options={expenseCodes.filter(
                    (e) => e.accountCode && e.organisationFk === -1
                  )}
                  getOptionLabel={(code) =>
                    code.accountCode
                      ? `${code.accountCode} - ${code.accountName}`
                      : ""
                  }
                  value={
                    expenseCodes.find(
                      (ec) => ec.accountCode == category.costAccountCode && ec.organisationFk === -1
                    ) ?? ""
                  }
                  onChange={(e, newValue) => {
                    setCategories(
                      categories.map((c) =>
                        c.id === category.id
                          ? {
                              ...category,
                              costAccountCode: newValue?.accountCode,
                            }
                          : c
                      )
                    );
                  }}
                  disableClearable
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  expenseCodes: state.data.expenseCodes,
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, {})(ProductTypeAccountCodeTable);
