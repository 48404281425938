import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    Typography,
    Checkbox
} from "@mui/material";
import { connect } from "react-redux";
import { getCategoryTypeByName, removeLeadingZeros } from "../../../Utils/JobHelpers";
import { truncateDecimals } from "../../../Utils/GeneralUtils";
import { calculateCurrencyRate } from "../../../Utils/ExchangeRateUtils";
import "./currencyConversionAssistant.scss";

const CurrencyConversionAssistant = ({
    open,
    handleClose,
    jobLine,
    job,
    handleOnChangeJobLine,
    validateSellPrice
}) => {
    const [budgetChecked, setBudgetChecked] = useState(true);
    const [markupChecked, setMarkupChecked] = useState(false);
    const [otherCurrencyTotal, setOtherCurrencyTotal] = useState(0);

    const handleCheckbox = (type) => {
        if (type === "budget") {
            setBudgetChecked(!budgetChecked)
            setMarkupChecked(budgetChecked)
        } else if (type === "markup") {
            setMarkupChecked(!markupChecked)
            setBudgetChecked(markupChecked)
        }
    }

    const handleConversion = () => {
        var nzdTotal = otherCurrencyTotal / job.exchangeRate;

        if (jobLine.rateType === "Open Budget") {
            if (budgetChecked) {
                var newBudget = nzdTotal / (1 + (jobLine.costPrice / 100));
                handleOnChangeJobLine(
                    jobLine.guid,
                    "sellPrice",
                    //truncateDecimals(Number(newBudget), 2)
                    Number(newBudget.toFixed(2))
                )
            } else if (markupChecked) {
                var newPercentage = ((nzdTotal / jobLine.sellPrice) - 1) * 100

                if (Math.round(newPercentage) < jobLine.minSellPrice) {
                    alert(`${job.currency} Amount is too low. This will change the markup below ${jobLine.minSellPrice}%`);
                    setOtherCurrencyTotal(jobLine.exchangedTotal);
                    return;
                }

                handleOnChangeJobLine(
                    jobLine.guid,
                    "costPrice",
                    //truncateDecimals(Number(newPercentage), 2)
                    Number(newPercentage.toFixed(2))
                )
            }
        } else {
            if (budgetChecked) {//Also used for quantity
                var newQuantity = nzdTotal / jobLine.sellPrice;
                if (newQuantity < 1) {
                    alert(`${job.currency} Amount is too low. This will change the quantity below 1`);
                    setOtherCurrencyTotal(jobLine.exchangedTotal);
                    return;
                }
                handleOnChangeJobLine(
                    jobLine.guid,
                    "quantity",
                    //truncateDecimals(Number(newQuantity.toFixed(2)), 2)
                    Number(newQuantity.toFixed(2))
                );
            } else if (markupChecked) {
                var newPrice = nzdTotal / jobLine.quantity

                if (Math.round(newPrice) < jobLine.minSellPrice) {
                    alert(`${job.currency} Amount is too low. This will change the price below the minimum of $${jobLine.minSellPrice}`);
                    setOtherCurrencyTotal(jobLine.exchangedTotal);
                    return;
                }

                handleOnChangeJobLine(
                    jobLine.guid,
                    "sellPrice",
                    //truncateDecimals(Number(newPrice), 2)
                    Number(newPrice.toFixed(2))
                );
            }
        }
    }

    useEffect(() => {
        setOtherCurrencyTotal(truncateDecimals(Number(jobLine.exchangedTotal), 2))
        //setOtherCurrencyTotal(Number(jobLine.exchangedTotal))
    }, [jobLine])

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if (reason !== "backdropClick") {
                    handleClose()
                }
            }}
            classes={{ paper: "currency-conversion-assistant-dialog" }}
        >
            <DialogTitle>Currency Conversion Assistant</DialogTitle>
            <DialogContent>
                <div className="currency-conversion-assistant-row">
                    <div style={{ gridColumn: "span 5" }}>
                        <DialogContentText>
                            {`This tool will help you calculate the price backwards from the price you want the client to pay.`}
                        </DialogContentText>
                        <DialogContentText>
                            {`1${job.currency} = ${calculateCurrencyRate(1, job.exchangeRate)}NZD`}
                        </DialogContentText>
                        <DialogContentText>
                            {`- Check the item you would like to adjust.`}
                        </DialogContentText>
                        <DialogContentText>
                            {`- Input the cost to client in ${job.currency}.`}
                        </DialogContentText>
                        <DialogContentText>
                            {`- Adjust values as needed`}
                        </DialogContentText>
                        <DialogContentText>
                            {`NOTE: Amounts are rounded to 2 decimal places`}
                        </DialogContentText>
                    </div>
                    <div className="nzd-amount-container">
                        <TextField
                            type="number"
                            id="cost"
                            disabled
                            label={`NZD Amount`}
                            variant="filled"
                            fullWidth={true}
                            size="small"
                            value={jobLine.total.toFixed(2)}
                        />
                    </div>
                </div>
                <div className="currency-conversion-assistant-row" style={{ paddingTop: 20 }}>
                    <TextField
                        key={"resource" + jobLine.guid}
                        id="resource"
                        label="Resource"
                        variant="filled"
                        fullWidth={true}
                        value={jobLine.name}
                        size="small"
                        disabled
                    />
                    <TextField
                        key={"description" + jobLine.guid}
                        id="description"
                        label="Description"
                        variant="filled"
                        fullWidth={true}
                        value={jobLine.description || ""}
                        size="small"
                        disabled
                    />
                    {jobLine && jobLine.rateType === "Open Budget" ? (
                        <TextField
                            type="number"
                            disabled={!budgetChecked}
                            id="cost"
                            label="Budget"
                            variant="filled"
                            error={!jobLine.sellPrice || jobLine.sellPrice === 0}
                            fullWidth={true}
                            value={jobLine.sellPrice ? removeLeadingZeros(jobLine.sellPrice) : 0}
                            size="small"
                            onBlur={(e) => {
                                validateSellPrice(jobLine);
                            }}
                            onChange={(e) => {
                                handleOnChangeJobLine(
                                    jobLine.guid,
                                    "sellPrice",
                                    Number(e.target.value)
                                )
                            }}
                        />
                    ) : (
                        <TextField
                            type="number"
                            disabled={!budgetChecked}
                            id="quantity"
                            label="Quantity"
                            variant="filled"
                            fullWidth={true}
                            value={removeLeadingZeros(jobLine.quantity)}
                            size="small"
                            onChange={(e) =>
                                handleOnChangeJobLine(
                                    jobLine.guid,
                                    "quantity",
                                    Number(e.target.value)
                                )
                            }
                        />
                    )}
                    <TextField
                        type="number"
                        id="cost-price"
                        label={
                            jobLine && jobLine.rateType === "Open Budget"
                                ? "Min Markup %"
                                : "Min Price"
                        }
                        variant="filled"
                        fullWidth={true}
                        value={removeLeadingZeros(jobLine.minSellPrice)}
                        disabled
                        size="small"

                    />
                    {jobLine && jobLine.rateType === "Open Budget" ? (
                        <TextField
                            label="Markup %"
                            type="number"
                            disabled={!markupChecked}
                            value={removeLeadingZeros(jobLine.costPrice)}
                            size="small"
                            variant="filled"
                            fullWidth={true}
                            onBlur={(e) => {
                                validateSellPrice(jobLine);
                            }}
                            onChange={(e) => {
                                handleOnChangeJobLine(
                                    jobLine.guid,
                                    "costPrice",
                                    Number(e.target.value)
                                );
                            }}
                        />
                    ) : (
                        <TextField
                            type="number"
                            disabled={!markupChecked}
                            id="cost"
                            label="Price"
                            variant="filled"
                            fullWidth={true}
                            value={jobLine.sellPrice ? removeLeadingZeros(jobLine.sellPrice) : 0}
                            size="small"
                            onBlur={(e) => {
                                validateSellPrice(jobLine);
                            }}
                            onChange={(e) =>
                                handleOnChangeJobLine(
                                    jobLine.guid,
                                    "sellPrice",
                                    Number(e.target.value)
                                )
                            }
                        />
                    )}
                    <TextField
                        type="number"
                        id="cost"
                        label={`${job.currency} Amount`}
                        variant="filled"
                        fullWidth={true}
                        size="small"
                        value={otherCurrencyTotal}
                        onChange={e => setOtherCurrencyTotal(e.target.value)}
                        onBlur={() => {
                            handleConversion()
                        }}
                        onEnter
                    />
                </div>
                <div className="currency-conversion-assistant-row">
                    <div style={{ gridColumn: "span 2" }}>
                        {jobLine && jobLine.rateType === "Open Budget" ?
                            <Typography>Select if you want to adjust the budget or the markup</Typography>
                            : <Typography>Select if you want to adjust the quantity or the price</Typography>
                        }
                    </div>
                    <div className="checkbox-container">
                        <Checkbox checked={budgetChecked} onClick={() => handleCheckbox("budget")} />
                    </div>
                    <div></div>
                    <div className="checkbox-container">
                        <Checkbox checked={markupChecked} onClick={() => handleCheckbox("markup")} />
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className="default-button" variant="contained" onClick={handleClose}>Close</Button>
                {/* <Button className="default-button" variant="contained" onClick={() => { handleConversion() }}>Save Changes</Button> */}
            </DialogActions>
        </Dialog >
    )
}

//export default CurrencyConversionAssistant;
const mapStateToProps = (state) => ({
    job: state.job.job
});
export default connect(mapStateToProps, {})(CurrencyConversionAssistant);