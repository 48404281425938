import { Button, Menu, MenuItem, Typography } from "@mui/material";
import React from "react";
import { v1 as uuidv1 } from "uuid";
import { connect } from "react-redux";
import { addNewJobLineStore } from "../../../reduxActions/job";
import "./socialitesAddRow.scss";
import { getCategoryTypes } from "../../../Utils/JobHelpers";
import { useAuth } from "../../../context/auth/AuthContext";
const SocialitesAddRow = ({
  setHasChanges,
  jobCategories,
  setJobCategories,
  addNewJobLineStore,
  products,
  isReadOnly,
  productCategories,
}) => {
  const { user } = useAuth();
  const [contextMenu, setContextMenu] = React.useState(null);
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleAddMultipleRows = async (count, categoryType) => {
    let lastOrder = [...jobCategories]
      .sort((a, b) => a.order - b.order)
      .map((j) => j.order);
    if (lastOrder && lastOrder.length > 0) {
      lastOrder = Number(lastOrder[lastOrder.length - 1]) + 2;
    } else {
      lastOrder = 1;
    }

    let categoryBlockName = "";

    if (categoryType === "Filming") {
      const filmingCategoriesCount = jobCategories.filter(
        (c) => c.type === "Filming"
      ).length;
      categoryBlockName = `Filming Day ${filmingCategoriesCount + 1}`;
    } else if (categoryType === "Pre Production") {
      categoryBlockName = "Pre Production";
    } else if (categoryType === "Post Production") {
      categoryBlockName = "Post Production";
    } else if (categoryType === "Other") {
      categoryBlockName = "Other";
    }else if (categoryType === "CapEx Mag") {
      categoryBlockName = "CapEx Mag";
    }
    await handleAddNewJobLineButton(
      {
        order: lastOrder,
        name: categoryBlockName,
        guid: uuidv1(),
        type: categoryType,
      },
      count
    );
    handleCloseAddRow();
  };

  const handleCloseAddRow = () => {
    setContextMenu(null);
  };

  const handleAddNewJobLineButton = async (category, rowsToAddCount) => {
    let jobCategoriesClone = [...jobCategories];
    jobCategoriesClone.push(category);
    setJobCategories(jobCategoriesClone);
    setHasChanges(true);
  };

  return (
    <div className="socialites-add-row-root">
      <Button
        variant="outlined"
        className="socialites-add-new-item-btn button-outlined-border"
        size="small"
        onClick={handleContextMenu}
        disabled={isReadOnly}
      >
        Add a department
      </Button>
      <Menu
        open={contextMenu !== null}
        onClose={handleCloseAddRow}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <>
          <Typography
            variant="caption"
            className="socialites-add-new-item-group-label"
          >
            Thompson Spencer
          </Typography>
          {productCategories
            .filter((c) => c.organisationId !== 1)
            .map((item) => (
              <MenuItem
                disabled={isReadOnly}
                onClick={(e) => {
                  handleAddMultipleRows(1, item.name);
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          {user?.email?.includes("magnesium") && (
            <>
              <Typography
                variant="caption"
                className="socialites-add-new-item-group-label"
              >
                Magnesium
              </Typography>
              {productCategories
                .filter((c) => c.organisationId === 1)
                .map((item) => (
                  <MenuItem
                    disabled={isReadOnly}
                    onClick={(e) => {
                      handleAddMultipleRows(1, item.name);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </>
          )}
        </>
      </Menu>
    </div>
  );
};
const mapStateToProps = (state) => ({
  products: state.product.products,
  productCategories: state.product.productCategories,
});

export default connect(mapStateToProps, {
  addNewJobLineStore,
})(SocialitesAddRow);
