import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  reorderJobLinesSameCategory,
  moveJobLinesBetweenCategories,
  deleteJobCategory,
  updateAllJobCategoriesStore
} from "../../../reduxActions/job";
import { IconButton, Tooltip } from "@mui/material";
import MarginDisplay from "../../Shared/MarginDisplay";
import { Help } from "@mui/icons-material";
import SocialitesJobLinesAccordionItem from "./SocialitesJobLinesAccordionItem";
import SocialitesAddRow from "./SocialitesAddRow";
import "./socialitesJobLinesAccordion.scss";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "../../Shared/ConfirmationDialog";
import Snack from "../../Shared/Snack";
import { currencySymbolFormat } from "../../../Utils/GeneralUtils";
import ExchangedTotal from "../total/ExchangedTotal";
import TotalRemaining from "../total/TotalRemaining";
import InfoDialog from "../../Shared/InfoDialog";
import { useAuth } from "../../../context/auth/AuthContext";

const SocialitesJobLinesAccordion = (props) => {
  const {
    jobLines,
    reorderJobLinesSameCategory,
    moveJobLinesBetweenCategories,
    deleteJobCategory,
    currency,
    crossOrganisationProject,
    jobOrganisation,
    handleSetUpdateCompanyData,
    companies,
    updateAllJobCategoriesStore
  } = props;
  const { user } = useAuth();
  const [openConfirmCategoryDeleteDialog, setOpenConfirmCategoryDeleteDialog] =
    useState(false);
  const [tempCategory, setTempCategory] = useState(null);
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [openBookingFeeDialog, setOpenBookingFeeDialog] = useState(false);
  const [savingCategories, setSavingCategories] = useState(false);
  const [openNoPermissionBookingFee, setOpenNoPermissionBookingFee] =
    useState(false);
  const [executeForceSave, setExecuteForceSave] = useState(false);

  const calculateGrandTotal = () => {
    return jobLines.reduce((a, b) => a + b.total, 0);
  };

  const calculateGrandTotalMargin = () => {
    return jobLines.reduce((a, b) => a + b.margin, 0);
  };

  const calculateTotalMarginPercentage = () => {
    const total = calculateGrandTotal();
    const marginProfit = calculateGrandTotalMargin();
    const grossProfitPercentage = total > 0 ? marginProfit / total : 0;
    return grossProfitPercentage;
  };

  const onDragEnd = (result) => {
    if (!props.isReadOnly) {
      const { source, destination } = result;
      // dropped outside the list
      if (!destination) {
        return;
      }
      const fromIndex = source.index;
      const toIndex = destination.index;

      // same category
      if (fromIndex !== toIndex) {
        reorderJobCategories(fromIndex, toIndex)
      } 
    }
  };

  const reorderJobCategories = (fromIndex, toIndex) => {
    // Step 1: Adjust the list to move the item from `fromIndex` to `toIndex`
    const updatedJobCategories = [...props.jobCategories]
    .sort((a, b) => a.order - b.order);
    const [movedItem] = updatedJobCategories.splice(fromIndex, 1);
    updatedJobCategories.splice(toIndex, 0, movedItem);
  
    // Step 2: Update the `order` field based on the new list order
    updateAllJobCategoriesStore(updatedJobCategories.map((jobCategory, index) => ({
      ...jobCategory,
      order: index + 1, 
    })));
  };

  const getItems = () => {
    return [...props.jobCategories]
      .sort((a, b) => a.order - b.order)
      .map((c, index) => (
        <Draggable key={`${c.guid}`} draggableId={`${c.guid}`} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className="socialites-items-accordion-container socialites-job-lines"
            >
              <SocialitesJobLinesAccordionItem
                key={c.guid}
                index={index}
                category={c}
                handleCollapseAccordionItem={props.handleCollapseAccordionItem}
                jobLines={
                  jobLines &&
                  jobLines.filter((j) => j.jobCategoryGuid === c.guid)
                }
                products={props.products}
                handleChangeProduct={props.handleChangeProduct}
                handleOnChangeJobLine={props.handleOnChangeJobLine}
                handleOnChangeCategory={props.handleOnChangeCategory}
                handleAddNewJobLineButton={props.handleAddNewJobLineButton}
                handleDeleteJobLine={props.handleDeleteJobLine}
                getMargin={props.getMargin}
                handleChangeProductRateType={props.handleChangeProductRateType}
                isReadOnly={props.isReadOnly}
                job={props.job}
                forceSaveDraftJob={props.forceSaveDraftJob}
                handleOpenBookingFeeDialog={handleOpenBookingFeeDialog}
                setTempCategory={setTempCategory}
                setOpenConfirmCategoryDeleteDialog={
                  setOpenConfirmCategoryDeleteDialog
                }
              />
            </div>
          )}
        </Draggable>
      ));
  };

  const handleOpenBookingFeeDialog = () => {
    if (user.role === "Super User") {
      setOpenBookingFeeDialog(true);
    } else {
      setOpenNoPermissionBookingFee(true);
    }
  };

  const handleCloseBookingFeeDialog = () => {
    setOpenBookingFeeDialog(false);
  };

  const handleCloseCategoryDeleteDialog = () => {
    setTempCategory(null);
    setOpenConfirmCategoryDeleteDialog(false);
  };

  const handleDeleteJobLinesByCategory = (e) => {
    e.preventDefault();
    setSavingCategories(true);
    deleteJobCategory(tempCategory)
      .then(() => {
        setExecuteForceSave(true);
      })
      .catch(() => {
        setSavingCategories(false);
        handleCloseCategoryDeleteDialog();
      });
  };

  const handleOpenCompanyUpdate = () => {
    handleSetUpdateCompanyData(
      companies.find((c) => c.id === props.job.companyId)
    );
    handleCloseBookingFeeDialog();
  };

  useEffect(() => {
    if (executeForceSave) {
      setExecuteForceSave(false);
      props.forceSaveDraftJob();
      setsnackText("The Quote has been update.");
      setopenSnack(true);
      setSavingCategories(false);
      handleCloseCategoryDeleteDialog();
    }
  }, [executeForceSave]);

  return (
    <>
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      {openConfirmCategoryDeleteDialog && tempCategory && (
        <ConfirmationDialog
          open={openConfirmCategoryDeleteDialog}
          handleClose={handleCloseCategoryDeleteDialog}
          submitLabel={"Confirm"}
          loading={savingCategories}
          title={"Please confirm to proceed!"}
          description={
            "This action will remove all resources allocated to this block."
          }
          handleSubmit={handleDeleteJobLinesByCategory}
        />
      )}

      {openNoPermissionBookingFee && (
        <InfoDialog
          open={openNoPermissionBookingFee}
          handleClose={() => {
            setOpenNoPermissionBookingFee(false);
          }}
          title={"Not Allowed"}
          context={"Please contact your manager to update the booking fee."}
        />
      )}

      {openBookingFeeDialog && (
        <ConfirmationDialog
          open={openBookingFeeDialog}
          handleClose={handleCloseBookingFeeDialog}
          submitLabel={"Confirm"}
          title={"Are you sure?"}
          description={
            "This action will affect all media invoices for this client."
          }
          handleSubmit={handleOpenCompanyUpdate}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        {props.jobCategories?.length > 0 && (
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="table-responsive"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="table text-nowrap mb-0 job-line-table">
                  {getItems()}
                </div>
              </div>
            )}
          </Droppable>
        )}
        {props.percent >= 0.4 && (
          <SocialitesAddRow
            setHasChanges={props.setHasChanges}
            jobCategories={props.jobCategories}
            setJobCategories={props.setJobCategories}
            isReadOnly={props.isReadOnly}
          />
        )}
        <div className="grand-total socialites-service-grandtotal-container">
          <div style={{ gridColumn: "span 4" }}></div>
          <div style={{ gridColumn: "span 2", textAlign: "right" }}>{`${
            props.job.isRetainer ? "Total Per Month" : "Grand Total:"
          }`}</div>
          <div style={{ marginLeft: "25px" }}>
            {calculateGrandTotal()?.toLocaleString("en-NZ", {
              style: "currency",
              currency: "NZD",
              minimumFractionDigits: 2,
            })}
          </div>
          <div style={{ marginLeft: "25px" }}>
            <MarginDisplay
              total={calculateGrandTotal()}
              margin={calculateGrandTotalMargin()}
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ marginLeft: "25px" }}>
              {calculateTotalMarginPercentage()?.toLocaleString("en-NZ", {
                style: "percent",
              })}
            </div>
            <div style={{ position: "relative" }}>
              <div className="percentage-tooltip-icon">
                <Tooltip
                  className="tooltip-css"
                  title="Percentage: Margin / Total"
                  placement="top"
                  arrow
                >
                  <Help />
                </Tooltip>
              </div>
            </div>
          </div>

          {/* <div style={{ marginLeft: "63px" }}></div> */}
        </div>
        {!!currency && currency !== "NZD" && <ExchangedTotal />}
        {!!crossOrganisationProject && <TotalRemaining />}
      </DragDropContext>
    </>
  );
};
const mapStateToProps = (state) => ({
  crossOrganisationProject: state.job.job.crossOrganisationProject,
  currency: state.job.job.currency,
  jobLines: state.job.job.jobLines,
  selectedOrganisation: state.organisation.selectedOrganisation,
  companies: state.company.companies,
});

export default connect(mapStateToProps, {
  reorderJobLinesSameCategory,
  moveJobLinesBetweenCategories,
  deleteJobCategory,
  updateAllJobCategoriesStore,
})(SocialitesJobLinesAccordion);
