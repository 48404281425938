import { Typography } from "@mui/material";
import React from "react";
import { calculateCurrencyRate } from "../../Utils/ExchangeRateUtils";
import { connect } from "react-redux";

const ForeignCurrencyNote = ({ currency, exchangeRate }) => {
  console.log(currency, exchangeRate);
  return (
    <div>
      {currency && currency !== "NZD" && exchangeRate && (
        <>
          <Typography style={{ fontSize: 16 }}>
            <strong>
              NOTE: The amounts this quote are in NZD. This client is configured
              to be quoted and invoiced in {currency}. The numbers in{" "}
              <span style={{ color: "red" }}>RED</span> are in {currency} and
              are what the client will see.
            </strong>
          </Typography>
          <Typography style={{ fontSize: 16 }}>
            <strong>
              The conversion rate we are using is 1 {currency} ={" "}
              {calculateCurrencyRate(1, exchangeRate)} NZD
            </strong>
          </Typography>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(ForeignCurrencyNote);
