import React, { Fragment, useEffect, useState } from "react";
// import LoadingDash from "./Home/LoadingDash";
import Forecasting from "./Home/Forecasting";
import SalesTable from "./Sales/SalesTable";
import NewJobButton from "./Sales/NewJobButton";
import { useHistory, useLocation } from "react-router-dom";
import { Container, Slide } from "@mui/material";
import SalesTabs from "./Sales/SalesTabs";
import Invoices from "./Invoices/Invoices";
import DataStudioDashboard from "./Home/DataStudioDashboard";
import AdditionalJobDialog from "./Sales/AdditionalJobDialog";
import { connect } from "react-redux";
import { resetJobStore } from "../reduxActions/job";
import { getCompanies } from "../reduxActions/company";
import { useAuth } from "../context/auth/AuthContext";

const Home = ({
  selectedOrganisation,
  resetJobStore,
  getCompanies,
  job
}) => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();
  const [loaded, setloaded] = useState(false);
  const [slideOut, setslideOut] = useState(false);
  const [showComponents, setshowComponents] = useState(true);
  const [activeTab, setActiveTab] = useState("JOBS");
  const [openAdditionalJobDialog, setOpenAdditionalJobDialog] = useState(false);

  useEffect(async () => {
    if (selectedOrganisation && job && selectedOrganisation?.id !== job?.organisationId) {
      await resetJobStore(selectedOrganisation?.id)
    }
  }, [selectedOrganisation, job.organisationId])

  useEffect(() => {
    const id = setTimeout(() => {
      setloaded(true);
    }, 100);
    return () => clearTimeout(id);
  }, []);

  useEffect(() => {
    if (loaded) {
      setslideOut(true);
    }
  }, [loaded]);

  useEffect(() => {
    if (slideOut) {
      const id = setTimeout(() => {
        setshowComponents(true);
      }, 1500);
      return () => clearTimeout(id);
    }
  }, [slideOut]);

  const handleAdditionalJob = (option) => {
    setOpenAdditionalJobDialog(true);
  };

  const handleCloseAdditionalDialog = () => {
    setOpenAdditionalJobDialog(false);
  };

  const redirectAddionalJob = () => {
    history.push("job/null/1");
  };

  return (
    <Container style={{ maxWidth: '90vw' }}>
      {/* maxWidth="xl" */}
      {openAdditionalJobDialog && (
        <AdditionalJobDialog
          open={openAdditionalJobDialog}
          handleClose={handleCloseAdditionalDialog}
          redirectAddionalJob={redirectAddionalJob}
        />
      )}
      {location.state?.fromGAuth && (
        <div>
          {/* {!slideOut && <div className="black-panel"></div>} */}
          {/* SLIDING BLACK DOOR */}
          {/* <div>
            <Slide
              in={!slideOut}
              mountOnEnter
              unmountOnExit
              timeout={{ appear: 0, exit: 1500 }}
              direction="left"
            >
              <div className="right-black-panel"></div>
            </Slide>
          </div>
          <div className="black-panel-div-overflow">
            <Slide
              in={!slideOut}
              mountOnEnter
              unmountOnExit
              timeout={{ appear: 0, exit: 1500 }}
              direction="right"
            >
              <div className="left-black-panel"></div>
            </Slide>
          </div> */}
        </div>
      )}
      {showComponents && (
        <Fragment>
          {/* <Forecasting /> */}
          {user && selectedOrganisation?.id ? (
            <NewJobButton handleAdditionalJob={handleAdditionalJob} />
          ) : (
            <></>
          )}

          {/* {selectedOrganisation && selectedOrganisation.id === 1 && (
            <DataStudioDashboard />
          )} */}
          <SalesTabs activeTab={activeTab} setActiveTab={setActiveTab} />
          {activeTab === "JOBS" && <SalesTable />}
          {activeTab === "INVOICES" && <Invoices />}
        </Fragment>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  selectedOrganisation: state.organisation.selectedOrganisation,
  job: state.job.job
});

// export default connect(mapStateToProps, {})(Home);
export default connect(mapStateToProps, { resetJobStore, getCompanies })(Home);
