import React, { useState, useEffect, memo } from "react";
import JobLinesAccordion from "./JobLinesAccordion";
import { v1 as uuidv1 } from "uuid";
import { connect } from "react-redux";
import {
  updateAllJobLinesStore,
  updateSingleJobLineStore,
  addNewJobLineStore,
  removeJobLineStore,
  addProductToJobLineStore,
  updateCategoryStore
} from "../../reduxActions/job";
import ConfirmationDialog from "../Shared/ConfirmationDialog";
import { Button, Divider, Typography } from "@mui/material";
import DuplicateJobLinesDialog from "./DuplicateJobLinesDialog";
import SocialitesJobLinesAccordion from "./socialites/SocialitesJobLinesAccordion";
import { truncateDecimals } from "../../Utils/GeneralUtils";
// import MediaContractItemsTotal from "./MediaContractItemsTotal";

const JobLines = (props) => {
  const {
    jobLines,
    job,
    products,
    updateAllJobLinesStore,
    updateSingleJobLineStore,
    addNewJobLineStore,
    removeJobLineStore,
    addProductToJobLineStore,
    refreshJobDescriptionEditor,
    selectedOrganisation,
    updateCategoryStore,
    forceSaveDraftJob,
    handleSetUpdateCompanyData
  } = props;

  const [openJobLineDeleteDialog, setOpenJobLineDeleteDialog] = useState(false);
  const [
    openDuplicateJobLinesDialog,
    setOpenDuplicateJobLinesDialog,
  ] = useState(false);
  const [tempJobLineId, setTempJobLineId] = useState(null);

  const [jobOrganisation, setJobOrganisation] = useState(selectedOrganisation?.id);
  const [jobOrgIsDifferent, setJobOrgIsDifferent] = useState(false);

  useEffect(() => {
    if(job && job.organisationId !== selectedOrganisation?.id && !jobOrgIsDifferent){
      setJobOrgIsDifferent(true);
      setJobOrganisation(props.job.organisationId);
    }
  }, [props.job]);

  useEffect(() => {
    if (props.shootDays && props.jobCategories) {
      let filmingCategories = props.jobCategories.filter((c) =>
        c.name.includes("Filming")
      );
      let existingFilmingLinesCount = filmingCategories
        ? filmingCategories.length
        : 0;
      let jobLinesClone = [...jobLines];
      let jobCategoriesClone = [...props.jobCategories];
      if (props.shootDays > existingFilmingLinesCount) {
        for (let i = existingFilmingLinesCount; i < props.shootDays; i++) {
          let filmingCategory = {
            order: i + 2,
            name: "Filming - Day " + (i + 1),
            type: "FILMING",
            guid:uuidv1(),
          };

          let jobLine = {
            guid: uuidv1(),
            category: "Filming - Day " + (i + 1),
            name: "",
            description: "",
            rateType: "",
            quantity: 1,
            minSellPrice: 0,
            sellPrice: 0,
            costPrice: 0,
            total: 0,
            margin: 0,
            product: {},
            productId: 0,
            order: 1,
            jobCategoryGuid: filmingCategory.guid
          };

          if (!job.id) {
            jobLinesClone.push(jobLine);
          }
          jobCategoriesClone.push(filmingCategory);
        }
      } else {
        let totalDaysToBeRemoved = existingFilmingLinesCount - props.shootDays;
        for (let i = 1; i <= totalDaysToBeRemoved; i++) {
          let categoryToBeRemoved =
            filmingCategories[existingFilmingLinesCount - i];
          jobLinesClone = jobLinesClone.filter(
            (j) => j.jobCategoryGuid !== categoryToBeRemoved.guid
          );
          jobCategoriesClone = jobCategoriesClone.filter(
            (j) => j.guid !== categoryToBeRemoved.guid
          );
        }
      }
      updateAllJobLinesStore(jobLinesClone);
      props.setJobCategories(jobCategoriesClone);
    }
  }, [props.shootDays]);

  useEffect(() => {
    if (job?.exchangeRate && jobLines.length > 0) {
      console.log("exchange rate changed")
      console.log("exchange rate", job.exchangeRate)
      recalculateJobExchangedTotal()
    }
  }, [job?.exchangeRate]);

  const recalculateJobExchangedTotal = () => {
    if (job?.exchangeRate && jobLines.length > 0) {
      const exchangedRate = job.exchangeRate;
      const updatedJobLines = job.jobLines.map((jobLine) => {
        const { total } = jobLine;
        const updatedJobLine = { ...jobLine }; // Creating a new object
        updatedJobLine.exchangedTotal = (total && total > 0) ? truncateDecimals(total * exchangedRate, 2) : 0;
        return updatedJobLine;
      });
      updateAllJobLinesStore(updatedJobLines);
    }
  }

  const handleCollapseAccordionItem = (jobCategory) => {
    props.setJobCategories(
      props.jobCategories.map((category) =>
        category.guid === jobCategory.guid ? jobCategory : category
      )
    );
  };

  const handleDeleteJobLine = async (jobLine) => {
    if (jobLine.productId) {
      setTempJobLineId(jobLine.guid);
      setOpenJobLineDeleteDialog(true);
    } else {
      await removeJobLineStore(jobLine.guid);
      props.setHasChanges(true);
    }
  };

  const handleConfirmDeleteJobLine = async () => {
    await removeJobLineStore(tempJobLineId);
    props.setHasChanges(true);
    handleCloseDeleteJobLineDialog();
  };

  const handleAddNewJobLineButton = async (category, rowsToAddCount, product = null) => {
    let jobLinesToBeAdded = [];

    for (let i = 0; i < rowsToAddCount; i++) {
      let jobLine = {
        guid: uuidv1(),
        category: category?.name,
        name: product?.name || "",
        description: "",
        rateType: product?.rateType || "",
        calculationMethod: product?.calculationMethod || "Margin",
        quantity: 1,
        minSellPrice: product?.minSellPrice || 0,
        sellPrice: product?.sellPrice || 0,
        costPrice: product?.costPrice || 0,
        total: product?.sellPrice || 0,
        margin: 0,
        product: product || {},
        productId: product?.id || 0,
        isPassThrough: 0,
        requiresJournaling: product?.requiresJournaling || 0,
        allowPassThrough: product?.allowPassThrough || 0,
        jobCategoryGuid: category?.guid,
        exchangedTotal: 0
      };

      jobLinesToBeAdded.push(jobLine);
    }

    await addNewJobLineStore(jobLinesToBeAdded, category);
    await updateSingleJobLineStore(jobLinesToBeAdded[0].guid, "total", product?.sellPrice || 0 )
    props.setHasChanges(true);
  };

  const handleOnChangeJobLine = (jobLineId, field, value) => {
    updateSingleJobLineStore(jobLineId, field, value);
    props.setHasChanges(true);
  };

  const handleOnChangeCategory = (category) => {
    updateCategoryStore(category);
    props.setHasChanges(true);
  };

  const getTotal = (j) => {
    return Number(j.quantity) * Number(j.sellPrice);
  };

  const getMargin = (j) => {
    return getTotal(j) - Number(j.quantity) * Number(j.costPrice);
  };

  const handleChangeProduct = (jobLineId, selectedProduct) => {
    addProductToJobLineStore(jobLineId, selectedProduct);
    props.setHasChanges(true);
  };

  const handleCloseDeleteJobLineDialog = () => {
    setOpenJobLineDeleteDialog(false);
    setTempJobLineId(null);
  };

  const handleChangeProductRateType = (
    jobLineId,
    productName,
    selectedRateType,
    categoryName
  ) => {
    if (productName && productName && products && products.length > 0) {
      const selectedProduct = products?.find(
        (p) =>
          p.name === productName &&
          p.rateType === selectedRateType &&
          categoryName?.includes(p.type)
      );
      addProductToJobLineStore(jobLineId, selectedProduct);
      props.setHasChanges(true);
    }
  };

  const handleCloseDuplicateJobLinesDialog = () => {
    setOpenDuplicateJobLinesDialog(false);
  };

  return (
    <div>
      {openJobLineDeleteDialog && (
        <ConfirmationDialog
          open={openJobLineDeleteDialog}
          handleClose={handleCloseDeleteJobLineDialog}
          submitLabel={"Confirm"}
          title={"Please confirm to proceed!"}
          description={
            "This action will permanently remove the line. Are you sure?"
          }
          handleSubmit={handleConfirmDeleteJobLine}
        />
      )}
      {openDuplicateJobLinesDialog && (
        <DuplicateJobLinesDialog
          open={openDuplicateJobLinesDialog}
          handleClose={handleCloseDuplicateJobLinesDialog}
          refreshJobDescriptionEditor={refreshJobDescriptionEditor}
          job={job}
          jobOrganisation={jobOrganisation}
          setHasChanges={props.setHasChanges}
        />
      )}
      <div className="after-divider flex-space-between ">
        <Typography variant="subtitle2" className="subtitle-bold">{`${job.isRetainer ? "Monthly Retainer" : "Statement of Work"}`}</Typography>
        {!!job.crossOrganisationProject && <Typography variant="h4" className="subtitle-bold">{`INTERNAL BUDGET`}</Typography>}
        
        {!props.isReadOnly && (
          <Button
            onClick={() => setOpenDuplicateJobLinesDialog(true)}
            style={{ color: "#000" }}
          >
            Duplicate From Previous Job
          </Button>
        )}
      </div>
      <SocialitesJobLinesAccordion
          jobCategories={props.jobCategories}
          products={products}
          handleChangeProduct={handleChangeProduct}
          handleOnChangeCategory={handleOnChangeCategory}
          handleOnChangeJobLine={handleOnChangeJobLine}
          getMargin={getMargin}
          handleCollapseAccordionItem={handleCollapseAccordionItem}
          handleAddNewJobLineButton={handleAddNewJobLineButton}
          handleDeleteJobLine={handleDeleteJobLine}
          handleChangeProductRateType={handleChangeProductRateType}
          isReadOnly={props.isReadOnly}
          percent={props.percent}
          setJobCategories={props.setJobCategories}
          setHasChanges={props.setHasChanges}
          job={props.job}
          forceSaveDraftJob={forceSaveDraftJob}
          jobOrganisation={jobOrganisation}
          handleSetUpdateCompanyData={handleSetUpdateCompanyData}
        />
      {/* {jobOrganisation !== 1 ? (
        <SocialitesJobLinesAccordion
          jobCategories={props.jobCategories}
          products={products}
          handleChangeProduct={handleChangeProduct}
          handleOnChangeCategory={handleOnChangeCategory}
          handleOnChangeJobLine={handleOnChangeJobLine}
          getMargin={getMargin}
          handleCollapseAccordionItem={handleCollapseAccordionItem}
          handleAddNewJobLineButton={handleAddNewJobLineButton}
          handleDeleteJobLine={handleDeleteJobLine}
          handleChangeProductRateType={handleChangeProductRateType}
          isReadOnly={props.isReadOnly}
          percent={props.percent}
          setJobCategories={props.setJobCategories}
          setHasChanges={props.setHasChanges}
          job={props.job}
          forceSaveDraftJob={forceSaveDraftJob}
          jobOrganisation={jobOrganisation}
          handleSetUpdateCompanyData={handleSetUpdateCompanyData}
        />
      ) : (
        <JobLinesAccordion
          jobCategories={props.jobCategories}
          products={products}
          handleChangeProduct={handleChangeProduct}
          handleOnChangeJobLine={handleOnChangeJobLine}
          getMargin={getMargin}
          handleCollapseAccordionItem={handleCollapseAccordionItem}
          handleAddNewJobLineButton={handleAddNewJobLineButton}
          handleDeleteJobLine={handleDeleteJobLine}
          handleChangeProductRateType={handleChangeProductRateType}
          isReadOnly={props.isReadOnly}
          jobOrganisation={jobOrganisation}
        />
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  jobLines: state.job.job.jobLines,
  job: state.job.job,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  updateAllJobLinesStore,
  updateSingleJobLineStore,
  addNewJobLineStore,
  removeJobLineStore,
  addProductToJobLineStore,
  updateCategoryStore,
})(
  JobLines
  // memo(JobLines, (props, nextProps) => {
  //   if (props.shootDays === nextProps.shootDays) {
  //     // don't re-render/update
  //     return true;
  //   }
  // })
);
