import React, { useState, useEffect, useContext } from "react";
import {
  Autocomplete,
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import FilterListIcon from "@mui/icons-material/FilterList";
import { connect } from "react-redux";
import { updateJobsFilter } from "../../reduxActions/job";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { UserContext, useUserContext } from "../../context/user/UserContext";
import { getTeamColours } from "../../Utils/GeneralUtils";
import JobFilterPopover from "./JobFilterPopover";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getCompanies } from "../../reduxActions/company";
import { useAuth } from "../../context/auth/AuthContext";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const JobFilters = ({
  jobsFilter,
  updateJobsFilter,
  companies,
  selectedOrganisation,
  getCompanies
}) => {
  const history = useHistory();
  const { allUsers } = useUserContext();
  const { user } = useAuth();
  const [selectedUsers, setSelectedUsers] = useState(jobsFilter.users);
  const [selectedTeam, setSelectedTeam] = useState(jobsFilter.team);
  const [keyword, setKeyword] = useState(
    jobsFilter.keyword !== undefined ? jobsFilter.keyword : ""
  );
  const [openJobFilterPopover, setOpenJobFilterPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoadingRetainersEndingSoon, setIsLoadingRetainersEndingSoon] = useState(null);
  const [retainersEndingSoonCount, setRetainersEndingSoonCount] = useState(null);

  useEffect(() => {
    if( user?.email){
      searchRetainersEndingSoon();
    }
  }, [selectedOrganisation]);

  useEffect(() => {
    if (jobsFilter.users && jobsFilter.users !== selectedUsers) {
      setSelectedUsers(jobsFilter.users);
    }
  }, [jobsFilter.users]);

  useEffect(() => {
    if (jobsFilter.team && jobsFilter.team !== selectedTeam) {
      setSelectedTeam(jobsFilter.team);
    }
  }, [jobsFilter.team]);

  const searchRetainersEndingSoon = async () => {
    if (selectedOrganisation?.id > 1) {
      setIsLoadingRetainersEndingSoon(true);
      axios
        .get(`/job/GetRetainersEndingSoon`)
        .then(({ data }) => {
          setRetainersEndingSoonCount(data.length);
        })
        .finally(() => setIsLoadingRetainersEndingSoon(false));
    }
  };

  const handleSearch = (keyword) => {
    updateJobsFilter("keyword", keyword?.trim());
    updateJobsFilter("status", ["draft", "checked", "sent", "approved"]);
    updateJobsFilter("users", []);
    updateJobsFilter("team", []);
  };

  const handleUsersChange = (e, selectedItems, actionType, details) => {
    if (selectedItems && selectedItems.length > 0) {
      updateJobsFilter("users", selectedItems.map((u) => u.email).join(","));
    } else {
      updateJobsFilter("users", []);
    }
  };

  const handleTeamChange = (e, selectedItems, actionType, details) => {
    if (selectedItems && selectedItems.length > 0) {
      updateJobsFilter("team", selectedItems.map((t) => t).join(","));
    } else {
      updateJobsFilter("team", []);
    }
  };

  // const handleSearch = () => {
  //   updateJobsFilter("users", selectedUsers);
  // };

  const handleJobFilterPopover = (event) => {
    setOpenJobFilterPopover(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilterPopover = () => {
    setOpenJobFilterPopover(false);
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        padding: 15,
        display: "flex",
        justifyContent: "flex-end",
        gap: 20,
      }}
    >
      {/* <Grid container direction="row" spacing={2} justifyContent="flex-end"> */}
      {/* <Grid item xs={3}> */}
      <TextField
        id="keyword"
        label="Global Search"
        variant="filled"
        fullWidth={true}
        value={keyword}
        size="small"
        onChange={(e) => {
          setKeyword(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 13 || e.key === "Enter") {
            handleSearch(keyword);
          }
        }}
        onBlur={(e) => {
          handleSearch(keyword);
        }}
      />
      {/* </Grid> */}
      {/* <Grid item xs={3}> */}
      <Autocomplete
        className="input-100-width"
        options={companies}
        getOptionLabel={(option) => option.name}
        value={companies?.find((c) => c.id === jobsFilter.companyId)}
        onChange={(e, newValue) => {
          console.log("Clear: "+newValue)
          let id = 0;
          if (newValue) {
            id = newValue.id;
          }
          updateJobsFilter("companyId", id);
          if(id != 0 && id != null){
            updateJobsFilter("status", ["draft", "checked", "sent", "approved"]);
          }
        }}
        onInputChange={(e, value, reason) => {
          if(reason === "clear"){
            updateJobsFilter("status", ["draft", "checked", "sent"]);
          }
        }}
        selectOnFocus
        clearOnBlur
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Company"
            size="small"
            fullWidth={true}
            variant="filled"
          />
        )}
      />
      {/* </Grid> */}
      {/* <Grid item xs={3}> */}
      <Autocomplete
        className="input-100-width"
        multiple
        options={allUsers}
        getOptionLabel={(option) => option.firstName}
        value={allUsers.filter((u) => selectedUsers.includes(u.email))}
        onChange={(e, items, type, details) => {
          console.log(e, items, type, details);
          handleUsersChange(e, items, type, details);
        }}
        disableCloseOnSelect
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              avatar={<Avatar alt="avatar" src={option.photoUrl} />}
              variant="outlined"
              label={option.firstName}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option) => {
          // console.log("Rendering option: ", option, "with props:", props);
          return (
            <li {...props} key={option.email}>
              <Avatar
                alt="avatar"
                src={option.photoUrl}
                style={{ marginRight: 10 }}
              />{" "}
              {option.firstName + " " + option.lastName}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Created By"
            variant="filled"
          />
        )}
        fullWidth
      />
      {/* </Grid> */}
      {/* <Grid item xs={3}> */}
      {/* <div> */}
      {/* <Autocomplete
        className="input-100-width"
        multiple
        options={getTeamColours()}
        getOptionLabel={(option) => option}
        value={getTeamColours().filter((t) => selectedTeam.includes(t))}
        onChange={(e, items, type, details) => {
          handleTeamChange(e, items, type, details);
        }}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} size="small" label="Team Colour" variant="filled" />
        )}
        fullWidth
      /> */}

      {/* </div>
          <div style={{position: "relative"}}> */}
      <IconButton
        style={{ color: "#000" }}
        aria-label="filter"
        onClick={handleJobFilterPopover}
      >
        <FilterListIcon />
      </IconButton>
      {selectedOrganisation?.id > 1 && (
        <Button
          className="default-button-outlined-no-hover"
          fullWidth
          startIcon={<LaunchIcon />}
          onClick={() => history.push({ pathname: "/ending-retainers" })}
        >
          <div className="button-flex-container">
            <Typography>View Retainers Ending Soon</Typography>
            {!isLoadingRetainersEndingSoon && retainersEndingSoonCount > 0 && (
              <div className="badge-notification-retainer-ending-soon">
                <Typography variant="caption">
                  <strong>{retainersEndingSoonCount}</strong>
                </Typography>
              </div>
            )}
          </div>
        </Button>
      )}
      <Button
        className="default-button-outlined-no-hover"
        fullWidth
        startIcon={<LaunchIcon />}
        onClick={() => history.push({ pathname: "/onboarding-center" })}
      >
        <div className="button-flex-container">
          <Typography>View Onboarding Center</Typography>
        </div>
      </Button>
      <JobFilterPopover
        open={openJobFilterPopover}
        handleClose={handleCloseFilterPopover}
        anchorEl={anchorEl}
        updateJobsFilter={updateJobsFilter}
      />

      {/* </div> */}
      {/* </Grid> */}
      {/* <Grid item>
          <Button
            variant="contained"
            className="default-button"
            onClick={handleSearch}
          >
            Search
          </Button>
        </Grid> */}
      {/* </Grid> */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  jobsFilter: state.job.jobsFilter,
  companies: state.company.companies,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  updateJobsFilter,
  getCompanies
})(JobFilters);
