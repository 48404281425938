import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import ProcessingSpinner from "../Shared/ProcessingSpinner";
import AddIcon from "@mui/icons-material/Add";
import {
  batchSaveProducts,
  updateProductDetails,
  createBlankProduct,
  archiveProduct,
  getAllProducts,
  getAllProductCategories,
  batchUpdateProductStore,
  createNewProduct,
  addBlankProductToStore,
  saveProductCategories,
} from "../../reduxActions/product";
import AddNewProductPopup from "./AddNewProductPopup";
import { Prompt, useParams } from "react-router-dom";
import ProductTypeAccountCodeTable from "./ProductTypeAccountCodeTable";
import axios from "axios";
import Snack from "../Shared/Snack";
import { useUserContext } from "../../context/user/UserContext";
import ProductCategoryAccordion from "./ProductCategoryAccordion";
import { useAuth } from "../../context/auth/AuthContext";

const Products = (props) => {
  const {
    products,
    getAllProducts,
    getAllProductCategories,
    selectedOrganisation,
    batchUpdateProductStore,
    createNewProduct,
    productCategories,
    saveProductCategories,
    archiveProduct
  } = props;
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [changes, setChanges] = useState([]);
  const [openAddNewProduct, setOpenAddNewProduct] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [openSnack, setopenSnack] = useState(false);
  const [snackSeverity, setsnackSeverity] = useState("info");
  const [snackText, setsnackText] = useState(false);
  const [categories, setCategories] = useState([]);
  const [updatedProducts, setUpdatedProducts] = useState([]);

  useEffect(() => {
    if (productCategories.length > 0) {
      loadUniqueCategories();
    }
  }, [productCategories]);

  useEffect(() => {
    if (user.email) {
      fetchData();
    }
  }, [user]);

  const loadUniqueCategories = () => {
    setCategories(productCategories);
  };

  const fetchData = async () => {
    if (selectedOrganisation && products.length === 0) {
      setLoading(true);
      await getAllProducts(0);
      await getAllProductCategories();
      setLoading(false);
    }
  };

  const handleSaveData = () => {
    setIsAutoSaving(true);
    const saveProductCategoriesUrl = axios.post(
      `Product/SaveProductCategories`,
      categories
    );

    const saveProductsUrl = axios.post(
      `Product/BatchSaveProducts`,
      updatedProducts
    );
    axios
      .all([saveProductCategoriesUrl, saveProductsUrl])
      .then(
        axios.spread((productCategoryRes, saveProductsUrlRes) => {
          saveProductCategories(productCategoryRes.data);
          batchUpdateProductStore(saveProductsUrlRes.data);
          setUpdatedProducts([]);
          setopenSnack(true);
          setsnackSeverity("success");
          setsnackText("Fantastic! The records have been saved.");
        })
      )
      .catch((err) => {
        console.log(err);
        setopenSnack(true);
        setsnackSeverity("warning");
        setsnackText("Something went wrong");
      })
      .finally(() => setIsAutoSaving(false));
  };

  const handleUpdateProductChangeList = (products) => {
    const updatedProductsMap = new Map(updatedProducts.map((p) => [p.id, p]));
  
    products.forEach((product) => {
      updatedProductsMap.set(product.id, product);
    });
  
    setUpdatedProducts(Array.from(updatedProductsMap.values()));
  };

  const handleCreateNewProduct = (newProduct) => {
    axios
      .post(`Product/SaveProduct`, newProduct)
      .then(({ data }) => {
        createNewProduct(data);
        setopenSnack(true);
        setsnackSeverity("success");
        setsnackText("Fantastic! The records have been saved.");
        setOpenAddNewProduct(false);
      })
      .catch((err) => {
        console.log(err);
        setopenSnack(true);
        setsnackSeverity("warning");
        setsnackText("Something went wrong");
      });
  };

  const handleArchiveProduct = (productId) => {
    axios
      .post(`Product/ArchiveProducts`, [productId])
      .then(({ data }) => {
        archiveProduct([productId]);
        setopenSnack(true);
        setsnackSeverity("success");
        setsnackText("Fantastic! The record has been archived.");
      })
      .catch((err) => {
        console.log(err);
        setopenSnack(true);
        setsnackSeverity("warning");
        setsnackText("Something went wrong");
      });
  };

  return (
    <div className="product-page-container">
      {openAddNewProduct && (
        <AddNewProductPopup
          open={openAddNewProduct}
          setOpen={setOpenAddNewProduct}
          handleCreateNewProduct={handleCreateNewProduct}
        />
      )}
      <Snack
        open={openSnack}
        setOpen={setopenSnack}
        severity={snackSeverity}
        text={snackText}
      />
      <Prompt
        when={hasChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Grid
        container
        direction="row"
        justify="space-between"
        //alignItems="center"
      >
        <Grid item xs={4} md={4} lg={4}>
          <Typography variant="h4" align="left">
            Products
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {isAutoSaving && <Alert severity="info">Saving...</Alert>}
        </Grid>
      </Grid>
      {loading ? (
        <ProcessingSpinner duration={60} unitTime={"seconds"} />
      ) : (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    handleSaveData();
                  }}
                  style={{ marginRight: 10 }}
                >
                  Save Changes
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ProductTypeAccountCodeTable
                categories={categories}
                setCategories={setCategories}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 15,
                  marginBottom: 20,
                }}
              >
                <Button
                  size="small"
                  variant="outlined"
                  endIcon={<AddIcon />}
                  onClick={(e) => {
                    // handleCreateBlankProduct();
                    setOpenAddNewProduct(true);
                  }}
                >
                  Add new product
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={(e) => {
                    handleSaveData();
                  }}
                  style={{ marginRight: 10 }}
                >
                  Save Changes
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <ProductCategoryAccordion
                products={products}
                productCategories={productCategories}
                handleUpdateProductChangeList={handleUpdateProductChangeList}
                handleArchiveProduct={handleArchiveProduct}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  expenseCodes: state.data.expenseCodes,
  products: state.product.products,
  productCategories: state.product.productCategories,
  selectedOrganisation: state.organisation.selectedOrganisation,
});

export default connect(mapStateToProps, {
  getAllProducts,
  getAllProductCategories,
  batchSaveProducts,
  updateProductDetails,
  createBlankProduct,
  archiveProduct,
  batchUpdateProductStore,
  createNewProduct,
  addBlankProductToStore,
  saveProductCategories,
})(Products);
