import { Checkbox, FormControlLabel, List, Popover } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateInvoiceFilter } from "../../reduxActions/invoice";

const FilterPopover = (props) => {
  let { invoiceFilter, updateInvoiceFilter } = props;
  return (
    <div>
      <Popover
        open={props.open}
        onClose={props.handleClose}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{style: { padding: 10 }}}
      >
        <div>
          <FormControlLabel
            label="Show Sent Invoices"
            control={
              <Checkbox
                checked={invoiceFilter?.includeSent}
                onChange={(e) => {
                  updateInvoiceFilter("includeSent", e.target.checked);
                }}
              />
            }
          />
        </div>
        <div>
          <FormControlLabel
            label="Show Awaiting PO Number"
            control={
              <Checkbox
                checked={invoiceFilter?.awaitingPoNumber}
                onChange={(e) => {
                  updateInvoiceFilter("awaitingPoNumber", e.target.checked);
                }}
              />
            }
          />
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state) => ({
  invoiceFilter: state.invoice.invoiceFilter,
});

export default connect(mapStateToProps, {
  updateInvoiceFilter,
})(FilterPopover);
